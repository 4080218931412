import React, {useCallback, useState, useEffect, createRef} from 'react'
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/free-solid-svg-icons'
import {useSpring, useSprings, animated } from '@react-spring/web'
import PolaroidsContainer from './PolaroidsContainer'

import "./styles.css";
import dates from './dates'


function Timeline(props) {
    
    // ActiveNodeIndex is the main state controller of the timeline. Identifying which data and which node is actively rendered
    const [activeNodeIndex, setActiveNodeIndex] = useState(0)

    // Nodes in the timeline, a node is created here for each entry we have in the "dates" file
    // These references are useful for controlling reacting to the scroll events
    const nodeRefs = React.useRef([]);
    nodeRefs.current = dates.map((_, i) => nodeRefs.current[i] ?? createRef());
    const numNodes = dates.length


    /**
     * ========================================================================================
     * ================================= UseSpring Animations =================================
     * ========================================================================================
     */
   

    // ================================= Node Animations =================================
    const inactiveColor = "#A9A9A9" // grey
    const activeColor = "#FFFFFF"   // white

    const [nodeStyles, setNodeStyles] = useSprings(numNodes, index => (
        {
            color: (index === activeNodeIndex) ? activeColor : inactiveColor
        }
    ))
    React.useEffect(() => {
        setNodeStyles.start(index => (
            {
                color: (index === activeNodeIndex) ? activeColor : inactiveColor
            }
        ));
    }, [activeNodeIndex, setNodeStyles]);


    // ================================= Date Animations =================================
    const dateInactiveFontSize = '16px'
    const dateActiveFontSize = '30px'
    const fontWeightInactive = 400
    const fontweightActive = 800

    const [dateStyles, setDateStyles] = useSprings(numNodes, index => (
        {
            fontSize: (index === activeNodeIndex) ? dateActiveFontSize : dateInactiveFontSize,
            color: (index === activeNodeIndex) ? activeColor : inactiveColor,
            fontWeight: (index === activeNodeIndex) ? fontweightActive : fontWeightInactive,
            transform: (index === activeNodeIndex) ? "translateX(-30%)" : "translateX(-5%)"
        }
    ))
    React.useEffect(() => {
        setDateStyles.start(index => (
            {
                fontSize: (index === activeNodeIndex) ? dateActiveFontSize : dateInactiveFontSize,
                color: (index === activeNodeIndex) ? activeColor : inactiveColor,
                fontWeight: (index === activeNodeIndex) ? fontweightActive : fontWeightInactive,
                transform: (index === activeNodeIndex) ? "translateX(-30%)" : "translateX(-5%)"
            }
        ));
    }, [activeNodeIndex, setDateStyles]);


    // ================================= Title Animations =================================
    const [titleFadeStyle, setTitleFadeStyle] = useSpring(() => ({
        from: {opacity: 0},
        to: {opacity: 1}
    }))

    React.useEffect(() => {
        setTitleFadeStyle.start(() => (
            {
                from: {opacity: 0},
                to: {opacity: 1},
                config: { duration: 1000 },
            }
        ));
    }, [activeNodeIndex, setTitleFadeStyle]);


    // ================================= Text Animations =================================
    const [textFadeStyle, setTextFadeStyle] = useSpring(() => ({
        from: {opacity: 0},
        to: {opacity: 1},
    }))

    React.useEffect(() => {
        setTextFadeStyle.start(() => (
            {
                from: {opacity: 0},
                to: {opacity: 1},
                config: { duration: 1000 },
            }
        ));
    }, [activeNodeIndex, setTextFadeStyle]);




    /**
     * ========================================================================================
     * ================================= Scroll Event Control =================================
     * ========================================================================================
     */

    const halfScreenHeight = window?.innerHeight / 2;
    const handleScroll = useCallback((e) => {
        nodeRefs.current.forEach((node, index) => {
            const rect = node.current.getBoundingClientRect();
            const nodeInActiveZone = (rect.bottom > halfScreenHeight && rect.top < halfScreenHeight);

            if (nodeInActiveZone) {
                setActiveNodeIndex(index)
            }
        });
    }, [halfScreenHeight]);

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        return () => {
          document.removeEventListener("scroll", handleScroll);
        };
      }, [handleScroll]);
    



    /**
     * ========================================================================================
     * ====================================== Component =======================================
     * ========================================================================================
     */
 
    return (
    <animated.div className = "bg-fixed h-full w-full min-h-screen flex flex-col items-center bg-cover" style={{backgroundImage: 'url(timelineBackground.jpg)'}}>
    <div className="container p-2 mx-auto">
        <div className="flex flex-row flex-wrap py-4">
            <main role="main" className="w-1/3 pt-1 px-2">

                {/*NOTE: Container Height here can break the site*/ }
                <div id="timeline-container" style = {{height:"1100vh"}} className="flex flex-col items-center" onScroll={handleScroll}>
                    <div id="lineStart" className="vlineLong" />
                    
                    {nodeRefs.current.map((nodeRef, i) => 
                        <div ref={nodeRef} className="flex flex-col items-center" key={i}>
                            <div className="vline"></div>
                            <div className="flex w-80 h-0">
                                <animated.div className="text-right" style={{flex: 1, ...dateStyles[i]}}>{dates[i].time}</animated.div>
                                <animated.div  style={nodeStyles[i]}>
                                    <FontAwesomeIcon icon={faCircle}/>
                                </animated.div>
                                <div style={{flex: 1}}></div>
                            </div>
                            <div className="vline"></div>
                        </div>
                    )}

                    <div id="lineEnd" className="vlineLong" />
                </div>

    
            </main>

            <aside className="fixed w-2/3 h-full items-center text-center right-0 px-2">
                <h1 className="text-6xl pt-40 pb-5">
                    <animated.div className="text-white font-bold" style={{...titleFadeStyle}}>{dates[activeNodeIndex].title}</animated.div>
                </h1>
                <div className = "text-3xl pb-10 h-20">
                    <animated.div className="text-white" style={{...textFadeStyle}}>{dates[activeNodeIndex].text}</animated.div>
                </div>
                
                <div className = "items-center">
                    <PolaroidsContainer activeIndex={activeNodeIndex}/>
                </div>
            </aside>

        </div>
    </div>
    </animated.div>
    );
}

export default Timeline