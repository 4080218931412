import TimelineWrapper from './components/myTimeline/TimelineWrapper';
import LoginPage from './components/LoginPage';
import IntroPage from './components/IntroPage';
import ErrorPage from './components/ErrorPage';
import ProtectedRoute from './components/ProtectedRoute';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    element: <ProtectedRoute />,
    children: [
            {
                path: "/intro",
                element: <IntroPage />,
            },
            {
                path: "/timeline",
                element: <TimelineWrapper />,
            }
    ],
  }
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
