import React, { useEffect, useState } from 'react'
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSquareCaretRight} from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom';
import AuthKey from './AuthKey'
import DateKey from './DateKey';

function LoginPage(props) {    
    // For now security is not very tight... simply matching against javascript here with login attempts stored in LocalStorage
    const datePassword = new Date(DateKey);
    const loginAttemptsStorageKey = "loginAttempts"; 
    const maxAttempts = 5;

    const [date, setDate] = useState(null)

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('')
    const [attempts, setAttempts] = useState(0)
    const [lockedOut, setLockedOut] = useState(false)

    useEffect(() => {
        let loginAttemptsFromStorage = localStorage.getItem(loginAttemptsStorageKey)
        console.log("Login attempts from storage:" + loginAttemptsFromStorage);
        if (loginAttemptsFromStorage > attempts) {
            setAttempts(parseInt(loginAttemptsFromStorage))
        } else {
            localStorage.setItem(loginAttemptsStorageKey, attempts)
        }

        if (attempts >= (maxAttempts-1)) {
            setLockedOut(true)
        }
    }, [attempts]);

    const handleDateChange = (element) => {
        console.log("received date change: " + element.target.value);
        setDate(new Date(element.target.value));
    }

    const handleInput = () => {
        if (date==null) { return }

        if (lockedOut) {
            setErrorMessage('All ' + maxAttempts + ' attempts used. You are locked out... FOREVER')
            return;
        }

        console.log("input date: " + date)
        console.log("compared with: " + datePassword)
        if (date.getTime() === datePassword.getTime()) {
            console.log("You're in!")
            localStorage.setItem(loginAttemptsStorageKey, 0) // reset attempts back to 0 in localStore
            sessionStorage.setItem('token', AuthKey)
            navigate('/intro')
        } else {
            setAttempts(attempts + 1)
            setErrorMessage('Try again. Attempt: ' + (attempts+1) + '/' + maxAttempts)
        }
    }

    return (
        <div className = "bg-fixed h-screen w-screen flex flex-col items-center bg-cover" style={{backgroundImage: 'url(timelineBackground.jpg)'}} >
            <h1 className = "text-4xl font-bold text-white justify-center text-center pt-20 pb-10 px-10">
                To Continue Select an Important Date:
            </h1>
            <div className = "justify-center pb-32">
                {errorMessage !== '' ?
                    <div role="alert">
                        <div className="bg-red-500 text-white font-bold rounded-t">
                            Failure
                        </div>
                        <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                            <p>{errorMessage}</p>
                        </div>
                    </div>
                :
                    <div/>
                }
            </div>
            <input type="date" onChange={handleDateChange}/>
            <div className="pt-20"> 
                <FontAwesomeIcon icon={faSquareCaretRight} size="4x" inverse onClick={handleInput}/>
            </div>

        </div>
    );
  }

export default LoginPage