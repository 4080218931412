import "./styles.css";
import dates from './dates'
import PolaroidImage from './PolaroidImage';


const PolaroidsContainer = ({activeIndex}) => { 
    return (
    <div className="relative w-6/12 h-full m-auto" >
        {dates.map((date, index) => {
            return(
            <div className="absolute top-0 w-full h-screen" >
                <PolaroidImage src={date.photo} alt={date.time} caption={date.time_exact} showImage={index === activeIndex} key={index}/>
            </div>
        )})}
    </div>
    )

}

export default PolaroidsContainer
