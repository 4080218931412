const dates = [
    {time: 'Apr 2018', time_exact: '04.08.2018', photo: '/memories/2018_April_FlowerField.jpg', title: 'Carslbad Flower Fields', text: 'One our first, most memorable dates (花田)'},
    {time: 'May 2018', time_exact: '05.26.2018', photo: '/memories/2018_MAY_LosAngeles.jpg', title: 'Los Angeles', text: 'First long-distance trip together, with many exciting memories'},
    {time: 'Jun 2018', time_exact: '06.22.2018', photo: '/memories/2018_JUN_RoadTrip.jpg', title: 'Road Trip!', text: 'Many adventures and long drives. The future was uncertain, but I was so happy spending that time with you.'},
    {time: 'Aug 2018', time_exact: '08.01.2018', photo: '/memories/2018_AUG_ByeBye.jpg', title: 'San Diego Airport', text: 'One of the hardest moments of my life - saying goodbye for now'},
    {time: 'Sep 2018', time_exact: '09.18.2018', photo: '/memories/2018_SEP_Beijing.jpg', title: 'Beijing', text: 'I can\'t describe how it felt to see you again in the airport after our month separated'},
    {time: 'Dec 2018', time_exact: '12.23.2018', photo: '/memories/2018_DEC_ChangBaiShan.jpg', title: 'Changbai Shan', text: 'Cold with icy winds. But I still felt warm being next to you again after so long apart'},
    {time: 'Mar 2019', time_exact: '03.26.2019', photo: '/memories/2019_MAR_Shanghai.jpg', title: 'Shanghai', text: 'Last trip to see you, with the longest gap in between'},
    {time: 'Jul 2019', time_exact: '07.07.2019', photo: '/memories/2019_JUL_Yosemite.jpg', title: 'Yosemite National Park', text: 'Long Hikes, great views, and lots of fun(besides the splinter).'},
    {time: 'Sep 2019', time_exact: '09.14.2019', photo: '/memories/2019_SEP_Freemont.jpg', title: 'Seattle, WA', text: 'We made it to Seattle! Painting a picture in freemont.'},
    {time: 'Oct 2019', time_exact: '10.06.2019', photo: '/memories/2019_OCT_Seattle.jpg', title: 'Seattle, WA', text: 'On the ferry to Bainbridge Island. Looks like it was quite windy!'},
    {time: 'Nov 2019', time_exact: '11.29.2019', photo: '/memories/2019_NOV_California.jpg', title: 'San Ramon, CA', text: 'Back to my family\'s home for Thanksgiving'},
    {time: 'Dec 2019', time_exact: '12.25.2019', photo: '/memories/2019_DEC_Banff.jpg', title: 'Banff, Canada', text: 'A snowy adventure: skating, skiing, and a horse-drawn sleigh'},
    {time: 'Mar 2020', time_exact: '03.28.2020', photo: '/memories/2020_MAR_Home.jpg', title: 'Seattle (Roosevelt)', text: 'Playing games together at home is so much fun! We are a great Luigi & Gooigi team.'},
    {time: 'May 2020', time_exact: '05.17.2020', photo: '/memories/2020_MAY_Puzzle.jpg', title: 'Seattle (Roosevelt)', text: 'Covid is in full-swing. Doing Puzzles together at home!'},
    {time: 'Jun 2020', time_exact: '06.14.2020', photo: '/memories/2020_JUN_Oregon.jpg', title: 'Lincoln City, Oregon', text: 'Even during covid, we found a way to travel. The Oregon coast was almost as beautiful as you!' },
    {time: 'Jul 2020', time_exact: '07.02.2020', photo: '/memories/2020_JUL_Olympics.jpg', title: 'Olympic National Park', text: 'Still one of my favorite hikes. Saw so much wildlife and flowers' },
    {time: 'Nov 2020', time_exact: '11.01.2020', photo: '/memories/2020_NOV_SanJuan.jpg', title: 'San Juan Islands', text: 'Cold but beautiful island walks and views (with a semi-working hottub)' },
    {time: 'Dec 2020', time_exact: '12.17.2020', photo: '/memories/2020_DEC_Sesame.jpg', title: 'Seattle (Scenic Appt)', text: 'Our first family picture with sesame - she looks very happy!' },
    {time: 'Feb 2021', time_exact: '02.12.2021', photo: '/memories/2021_FEB_CNY.jpg', title: 'Seattle (Scenic Appt)', text: 'Dressed up for Chinese new year. Was this before or after devouring the Peking duck?' },
    {time: 'Mar 2021', time_exact: '03.22.2021', photo: '/memories/2021_MAR_Chelan.JPG', title: 'Lake Chelan, WA', text: 'Too cold for water sports, but a nice break from work. Wine tasting, and enjoying nature!' },
    {time: 'May 2021', time_exact: '05.02.2021', photo: '/memories/2021_May_LakeUnion.jpg', title: 'Lake Union, Seattle', text: 'Taking our new Kayak for a spin! We struggled a bit, but it was still fun' },
    {time: 'Jul 2021', time_exact: '07.13.2021', photo: '/memories/2021_JUL_Alaska.jpg', title: 'Alaska', text: 'Horse riding, ice sculptures, kayaking, wildlife, and the never-setting sun; a big adventure' },
    {time: 'Jan 2022', time_exact: '01.03.2022', photo: '/memories/2022_JAN_Mexico.jpg', title: 'Mexico', text: 'Picture taken moments before getting a great price on some masks. Some of the best hotels and beaches' },
    {time: 'Apr 2022', time_exact: '04.23.2022', photo: '/memories/2022_APR_NYC.jpg', title: 'New York City', text: 'Even though you\'re not a big fan. I\'m glad I got to see the big apple with you' },
    {time: 'Jun 2022', time_exact: '06.17.2022', photo: '/memories/2022_JUN_Maine.jpg', title: 'Acadia National Park, Maine', text: 'Sad to be separated during your trip, but the hike together was a good one!' },
    {time: 'Aug 2022', time_exact: '08.27.2022', photo: '/memories/2022_AUG_SLU.jpg', title: 'Lake Union Park, Seattle', text: 'A month spent mostly at home, but still enjoyed every second spent with you.' },
    {time: 'Sep 2022', time_exact: '09.04.2022', photo: '/memories/2022_SEP_Rainier.jpg', title: 'Mt Rainier National Park', text: 'Not my favorite National Park, but hiking with you was still a lot of fun' },
    {time: 'Nov 2022', time_exact: '11.29.2022', photo: '/memories/2022_NOV_Hawaii.jpg', title: 'Kauai, Hawaii', text: 'We finally made it to Hawaii! From the relaxing, to the intense hikes there\'s no one else I\'d rather be with' },
    {time: 'Dec 2022', time_exact: '12.05.2022', photo: '/memories/2022_DEC_Hawaii.jpg', title: 'O\'ahu, Hawaii', text: 'Got to see some disney magic at Aulani' },
    {time: 'Jun 2023', time_exact: '06.16.2023', photo: '/memories/2023_JUN_VEGAS.jpg', title: 'Las Vegas, NV', text: 'The weather was hot, and so were you! Place your bets please' },
    {time: 'Jul 2023', time_exact: '07.01.2023', photo: '/memories/2023_JUL_SOUNDERS.jpg', title: 'Seattle, WA', text: 'Picture before standing for 2 hours. Thanks for great birthday exprience!' },
    {time: 'Aug 2023', time_exact: '08.19.2023', photo: '/memories/2023_AUG_Victoria.jpg', title: 'Victoria, Canada', text: 'Tea time, surrounded by flowers. Enjoying the end of summer.' },
    {time: 'Oct 2023', time_exact: '10.27.2023', photo: '/memories/2023_OCT_NYC.jpg', title: 'New York City', text: 'Back again. This time we went to a spy museum!' },
    {time: 'Dec 2023', time_exact: '12.09.2023', photo: '/memories/2023_DEC_California.jpeg', title: 'California', text: 'Attending James and Jeesoo\'s wedding. We look great!' },
    {time: 'Jan 2024', time_exact: '01.25.2024', photo: '/memories/2024_JAN_Macao.jpg', title: 'Macao', text: 'Exploring China together after a month apart. So much good food!' },
    {time: 'Feb 2024', time_exact: '02.11.2024', photo: '/memories/2024_FEB_Xiaogan.jpg', title: 'Xiaogan', text: 'Meeting your family for the first time, was fun and nerve-racking. Thanks for showing me around!' },
    {time: 'Apr 2024', time_exact: '04.20.2024', photo: '/memories/ring.jpg', title: 'Will You Marry Me?', text: '' },
]

export default dates