import {useSpring, animated } from '@react-spring/web'
import "./styles.css";

const PolaroidImage = ({src, alt, caption, showImage}) => {

    const imageStyles = useSpring({
        from: {
            opacity: 0,
        },
        to: {
            opacity: showImage ? 1 : 0,
        },
    })

    const imageStyles2 = useSpring({
        from: {
            filter: "grayscale(100%)"
        },
        to: {
            filter: showImage ? "grayscale(0%)" : "grayscale(100%)"
        },
        delay: 500,
        config: { duration: 3000 }
    })

    return(
        <animated.div className="item" style={{...imageStyles, ...imageStyles2 }}>
            <div className="polaroid" >
                <img src={src} alt={alt}/>
                <div className="caption">{caption}</div>
            </div>
        </animated.div>
    )
}

export default PolaroidImage
