import React, {useState, useEffect} from 'react'
import TimelineMini from './TimelineMini';
import Timeline from './Timeline';


const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState('undefined');
  
    useEffect(() => {
      function getScreenSize() {
        if (window.innerWidth < 1024) return 'sm';
        return 'lg';
      }
  
      function handleResize() {
        setScreenSize(getScreenSize());
      }
  
      handleResize();
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return screenSize;
  };

  
  
function  ResponsiveComponent(props) {
    const size = useScreenSize();
  
    return <>{props.children({ size })}</>;
  };


function TimelineWrapper(props) {

    return (
        <ResponsiveComponent>
        {({ size }) => (size === 'sm' ? <TimelineMini /> : <Timeline />)}
      </ResponsiveComponent>
    );
}

export default TimelineWrapper