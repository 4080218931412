import React from "react";
import AuthKey from "./AuthKey";

import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
	const storageToken = sessionStorage.getItem("token");
    console.log('Storage Token: ' + storageToken)

	return (storageToken === AuthKey)? <Outlet /> : <Navigate to="/"  replace />;
};

export default ProtectedRoute;