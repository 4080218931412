import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSquareCaretRight} from '@fortawesome/free-regular-svg-icons'


function IntroPage(props) {
    const titleStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1},
        config: { duration: 1500 }
    })

    const textStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1},
        delay: 1500,
        config: { duration: 1500 }
    })

    const buttonStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1},
        delay: 3000,
        config: { duration: 1500 }
    })



    const title = "Dear Yuting,"
    const text = "The six years we've spent together seems to have gone by so fast. Looking back on it, we've done so much, but when i'm with you time seems to fly away and I always wish we had more of it. \n \n " + 
                 "Over the past six years, we've made some great memories and been on some wonderful adventures..."
    
    return (
        <div className = "h-full w-full min-h-screen flex flex-col items-center bg-cover" style={{backgroundImage: 'url(timelineBackground.jpg)'}} >
            <animated.div className="text-4xl font-bold text-white justify-center pt-20 pb-32" style={{...titleStyles}}> {title}</animated.div>
            <animated.div className="text-xl w-8/12 font-semibold text-white justify-center pb-20" style={{whiteSpace: "pre-line",...textStyles}}>  {text} </animated.div>
            <animated.div className="pb-32" style={{...buttonStyles}}> 
                <a href="/timeline">
                    <FontAwesomeIcon icon={faSquareCaretRight} size="4x" inverse/>
                </a>
            </animated.div>

        </div>
    );
  }

export default IntroPage